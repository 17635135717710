import React from "react";
import { Redirect } from "react-router-dom";

class DelayLink extends React.Component {
  constructor(props) {
    super(props);
    // defaults (can't overwrite read-only so saving to local)
    this.timer = this.props.timer;
    if (this.timer === undefined) {
      this.timer = 1000;
    }
    this.onClick = this.props.onClick;
    if (this.onClick === undefined) {
      this.onClick = function() {};
    }
    // initialize state
    this.state = {redirecting: false};
    this.timerID = undefined;
  }

  doOnClick() {
    // only proceed with click action if not already clicked
    if (this.timerID === undefined) {
      this.onClick();
      this.timerID = setTimeout(() => this.setState({redirecting: true}), this.timer);
    }
  }

  render() {
    // using a button for onClick functionality (also warnings trying to use <a> element unconventionally)
    return (
      <button className={this.props.className} id={this.props.id} style={this.props.style} onClick={() => this.doOnClick()} disabled={this.props.disabled}>
        {this.props.children}
        {(this.state.redirecting) ? <Redirect push to={this.props.to} /> : ""}
      </button>
    );
  }
}

export default DelayLink;