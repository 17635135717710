import React from "react";
import { connect } from "react-redux";
import { CountUp } from "countup.js";
//import VertCenterText from "./VertCenterText.js";
import "./ScoreBlock.scss";
import * as RTRConst from "./Constants.js";
import iconGreen from "./img/icon-big/green.png";
import iconPurple from "./img/icon-big/purple.png";
import iconYellow from "./img/icon-big/yellow.png";
import iconRed from "./img/icon-big/red.png";

class RTRScoreBlock extends React.Component {

  componentDidMount() {
    this.doAnimateScore("rtrScoreNumber" + this.props.teamID, this.props.scores[this.props.teamID]);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.scores[prevProps.teamID] !== this.props.scores[this.props.teamID]) {
      this.doAnimateScore("rtrScoreNumber" + this.props.teamID, this.props.scores[this.props.teamID]);
    }
  }

  componentWillUnmount() {
    clearInterval(this.scoreChecker);
  }

  checkForScore() {
    const { scores, teamID } = this.props;
    let curScore = scores[teamID];
    if (curScore !== 0) {
      clearInterval(this.scoreChecker);
      this.doAnimateScore("rtrScoreNumber" + teamID, curScore);
    }
  }

  doAnimateScore(id, score) {
    let counter = new CountUp(id, score);
    counter.start();
  }

  render() {
    // get score data
    const { teamID } = this.props;
    // select team name
    let teamName = RTRConst.TEAM_NAMES[teamID];
    // select team icon
    let chosenIcon;
    switch (teamID) {
      case RTRConst.TEAM_GREEN:
        chosenIcon = iconGreen;
        break;
      case RTRConst.TEAM_YELLOW:
        chosenIcon = iconYellow;
        break;
      case RTRConst.TEAM_PURPLE:
        chosenIcon = iconPurple;
        break;
      case RTRConst.TEAM_RED:
        chosenIcon = iconRed;
        break;
      default:
        chosenIcon = "";
        console.log("no icon found for " + teamID);
        break;
    }
    return (
      <span className={"rtrScoreBlock" + (this.props.big ? " rtrScoreBlockBig" : "")}>
        <span className="rtrScoreIcon rtrWhiteShadow">
          <img src={chosenIcon} alt={teamName} />
        </span>
        <span className={"rtrScoreData rtrTeamBG" + teamID + " rtrTeamShadow" + teamID}>
          <span className="rtrScoreNumber" id={"rtrScoreNumber" + teamID}>
          {this.props.scores && this.props.scores[this.props.teamID] ? this.props.scores[this.props.teamID] : 0 }
          </span>
          <span className="rtrScoreName">
            {teamName}
          </span>
        </span>
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    scores: state.teamScores
  };
}

export default connect(mapStateToProps)(RTRScoreBlock);
