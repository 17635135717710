import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import * as RTRConst from "./Constants.js";
import * as RTRAct from "./Actions.js";

// initial state
const initialState = {
  // user login/team state
  isLoggedIn: false,
  teamID: RTRConst.TEAM_NONE,
  // team scores exported from stats data
  teamScores: [0, 0, 0, 0],
  // session times exported from session data
  sessionStart: 0,
  sessionEnd: 2,
  sessionUTC: 1,
  sessionEndAsString: undefined,
  // data of entire session
  sessionData: undefined,
  // last and current update status
  sessionUpdated: 0,
  sessionLoading: false,
  // featured quest on main menu
  questFeatured: "",
  questFeaturedData: undefined,
  questFeaturedLoading: false,
  // quest result data from last submission
  questResultData: undefined,
  questResultUpdated: false,
  // quest being viewed
  questLoadedData: undefined,
  questLoading: false,
  // if viewing featured quest
  questViewedIsFeatured: false,
  // if viewing in preview mode
  questViewedIsPreview: false,
  // data of current stats
  statsData: undefined,
  statsNewsfeed: undefined,
  statsTopQuests: undefined,
  // last and current stats status
  statsUpdated: 0,
  statsLoading: false,
  statsNewsfeedLoading: false,
  statsTopQuestsLoading: false,
  // chart data
  chartData: undefined,
  chartLoading: false,
  chartLocalData: undefined,
  chartLocalLoading: false
}

// main reducer
function RTRStoreReducer(state = initialState, action) {
  // get updated time from request if available
  let newUTC = state.sessionUTC;
  if (action.data !== undefined && action.data.meta !== undefined && action.data.meta.time !== undefined) {
    newUTC = action.data.meta.time;
  }
  // set state here
  switch (action.type) {
    // debug
    case RTRAct.DEBUG_DISPATCH:
      return state;

    case RTRAct.SELECT_TEAM:
      // DEBUG: randomly select team if not specified, handle the whole thing serverside later
      if (action.selected === undefined) {
        let pickTeam = Math.floor(Math.random() * RTRConst.TEAM_LIST.length);
        action.selected = pickTeam;
      }
      return Object.assign({}, state, { teamID: action.selected });
    // session retrieval
    case RTRAct.GET_SESSION:
      return Object.assign({}, state, { sessionLoading: true });
    case RTRAct.RCV_SESSION:
      if (action.data !== undefined) {
        let newDate = Math.floor(Date.UTC() / 1000);
        let newStart = RTRAct.getSessionStart(action.data);
        if (newStart === undefined) {
          newStart = state.sessionStart;
        }
        let newEnd = RTRAct.getSessionEnd(action.data);
        if (newEnd === undefined) {
          newEnd = state.sessionEnd;
        }
        return Object.assign({}, state, { sessionUTC: newUTC, sessionLoading: false, sessionUpdated: newDate, sessionData: action.data, sessionStart: newStart, sessionEnd: newEnd, sessionEndAsString: RTRAct.unixDateToUTC(newEnd) });
      } else {
        return Object.assign({}, state, { sessionLoading: false });
      }
    // quest retrieval
    case RTRAct.GET_QUEST:
      return Object.assign({}, state, { questLoading: true });
    case RTRAct.RCV_QUEST:
      if (action.data !== undefined) {
        return Object.assign({}, state, { sessionUTC: newUTC, questLoading: false, questLoadedData: action.data });
      } else {
        return Object.assign({}, state, { questLoading: false });
      }
    // stats retrieval
    case RTRAct.GET_STATS:
      return Object.assign({}, state, { statsLoading: true });
    case RTRAct.RCV_STATS:
      if (action.data !== undefined) {
        let newDate = Math.floor(Date.UTC() / 1000);
        let newScores = RTRAct.getTeamScores(action.data.data);
        return Object.assign({}, state, { sessionUTC: newUTC, statsData: action.data, teamScores: newScores, statsUpdated: newDate, statsLoading: false });
      } else {
        return Object.assign({}, state, { statsLoading: false });
      }
    // additional stats retrieval
    case RTRAct.GET_STATS_NEWSFEED:
      return Object.assign({}, state, { statsNewsfeedLoading: true });
    case RTRAct.RCV_STATS_NEWSFEED:
      if (action.data !== undefined) {
        return Object.assign({}, state, { sessionUTC: newUTC, statsNewsfeed: action.data.data, statsNewsfeedLoading: false });
      } else {
        return Object.assign({}, state, { statsNewsfeedLoading: false });
      }
    case RTRAct.GET_STATS_TOPQUEST:
      return Object.assign({}, state, { statsTopQuestsLoading: true });
    case RTRAct.RCV_STATS_TOPQUEST:
      if (action.data !== undefined) {
        return Object.assign({}, state, { sessionUTC: newUTC, statsTopQuests: action.data.data, statsTopQuestsLoading: false });
      } else {
        return Object.assign({}, state, { statsTopQuestsLoading: false });
      }
    // chart retrieval
    case RTRAct.GET_CHART:
      return Object.assign({}, state, { chartLoading: true });
    case RTRAct.RCV_CHART:
      if (action.data !== undefined) {
        return Object.assign({}, state, { sessionUTC: newUTC, chartData: action.data.data, chartLoading: false });
      } else {
        return Object.assign({}, state, { chartLoading: false });
      }
    case RTRAct.GET_CHART_LOCAL:
      return Object.assign({}, state, { chartLocalLoading: true });
    case RTRAct.RCV_CHART_LOCAL:
      if (action.data !== undefined) {
        return Object.assign({}, state, { sessionUTC: newUTC, chartLocalData: action.data.data, chartLocalLoading: false });
      } else {
        return Object.assign({}, state, { chartLocalLoading: false });
      }
    // quest submission result
    case RTRAct.QUEST_RESULT:
      if (action.data !== undefined) {
        return Object.assign({}, state, { sessionUTC: newUTC, questResultData: action.data.data, questResultUpdated: true });
      } else {
        return Object.assign({}, state, { questResultData: undefined, questResultUpdated: false });
      }
    // quest viewing
    case RTRAct.QUEST_VIEW_TYPE:
      return Object.assign({}, state, { questViewedIsFeatured: action.isFeatured, questViewedIsPreview: action.isPreview });

    default:
      return state;
  }
}

// create store
const RTRStore = createStore(RTRStoreReducer, applyMiddleware(thunkMiddleware));

export default RTRStore;
