import React from "react";
import "./Logo.scss";
import logoImg from "./img/title-rule-roost.png";

class RTRLogo extends React.Component {
  render() {
    return (
      <div className="rtrLogo">
        <img src={logoImg} alt="Rule The Roost"/>
      </div>
    );
  }
}

export default RTRLogo;
