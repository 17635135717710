// element
export const ELEMENT_ID = "rtrApp-root";

// teams
export const TEAM_NONE = -1;
export const TEAM_GREEN = 0;
export const TEAM_PURPLE = 1;
export const TEAM_YELLOW = 2;
export const TEAM_RED = 3;
export const TEAM_LIST = [TEAM_GREEN, TEAM_PURPLE, TEAM_YELLOW, TEAM_RED];
export const TEAM_NAMES = ["Playful Peacocks", "Heroic Hummingbirds", "Clever Canaries", "Fierce Flamingos"];

// visual/audio control (milliseconds)
export const FADE_TIME = 300;
export const SLIDE_TIME = 500;
export const VO_DELAY = 500;

// device visual control
export const WIDTH_MOBILE_LIMIT = 768;

// animation for picking (milliseconds)
export const PICK_MIN_CYCLE_TIME = 2050;
export const PICK_MAX_SLOWDOWN_TIME = 4500;
export const PICK_STARTING_DELAY = 100;
export const PICK_SLOWDOWN_INCREMENT = 100;
export const PICK_SLOWDOWN_SLOWEST = 700;

// stats
//export const TAB_PLAYERS = 0;
export const TAB_QUESTS = 0;
export const TAB_NEWS = 1;
export const TAB_ALL = 2;
export const NO_DATA_MSG = "The game just started! Keep playing and check again soon!";
export const STATS_LIST_MAX_MOBILE = 8;
export const STATS_LIST_MAX_DESKTOP = 12;

// states
export const US_STATES = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California",
  "Colorado", "Connecticut", "Delaware", "District of Columbia", "Florida",
  "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana",
  "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine",
  "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
  "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
  "New Jersey",  "New Mexico", "New York", "North Carolina", "North Dakota",
  "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island",
  "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah",
  "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];
export const US_STATES_SHORT = [
  "al", "ak", "az", "ar", "ca",
  "co", "ct", "de", "dc", "fl",
  "ga", "hi", "id", "il", "in",
  "ia", "ks", "ky", "la", "me",
  "md", "ma", "mi", "mn", "ms",
  "mo", "mt", "ne", "nv", "nh",
  "nj", "nm", "ny", "nc", "nd",
  "oh", "ok", "or", "pa", "ri",
  "sc", "sd", "tn", "tx", "ut",
  "vt", "va", "wa", "wv", "wi", "wy"
];

// question data
export const MAX_QUESTIONS = 10;
export const ANSWER_UNSELECTED = -1;

// time before refresh data (seconds)
export const SESSION_REFRESH_TIMEOUT = 300; // 5 minutes
export const STATS_REFRESH_TIMEOUT = 300; // 5 minutes

// time to hold cookies
export const COOKIE_DAY_TIMEOUT = 60 * 60 * 24;
export const COOKIE_HALFDAY_TIMEOUT = COOKIE_DAY_TIMEOUT / 2;
export const COOKIE_FEATURED_TIMEOUT = COOKIE_HALFDAY_TIMEOUT;

// fallbacks
export const FALLBACK_CATEGORY_COLOR = "#666";
export const FALLBACK_CATEGORY_ID = "unknown";
export const FALLBACK_CATEGORY_NAME = "Unknown";
