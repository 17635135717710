import React from "react";
import { connect } from "react-redux";
import "./Home.scss";
import DelayLink from "./DelayLink.js";
import RTRLogo from "./Logo.js";
import RTRTimer from "./Timer.js";
import RTRScoreBlock from "./ScoreBlock.js";
import RTRCatIcon from "./CatIcon.js";
import * as RTRConst from "./Constants.js";
import * as RTRAct from "./Actions.js";

class RTRHome extends React.Component {
  constructor(props) {
    super(props);
    // get last featured answered question ID
    let prevFeatureAnswer = RTRAct.getCookieValueFromList(RTRAct.getCookieList(), RTRAct.FEATURED_ANSWERED);
    if (prevFeatureAnswer !== undefined) {
      prevFeatureAnswer = parseInt(prevFeatureAnswer);
    }
    this.state = {prevFeatureAnswer: prevFeatureAnswer};
  }

  componentDidMount() {
    setTimeout(() => RTRAct.viewFadeIn("rtrHome"), RTRConst.FADE_TIME);
    // load stats
    this.props.getStats();
  }

  render() {
    // get featured quest
    let featuredQuest = RTRAct.getSessionFeatured(this.props.sessionData);
    let featuredAnswered = (featuredQuest.id === this.state.prevFeatureAnswer);
    
    //video
    //change url, share link or id into embedded format
    function formatVideo(video){      
      const formattedVideo = 
        video.split("https://youtu.be/")[1] ? video.split("https://youtu.be/")[1] : 
        video.split("https://www.youtube.com/watch?v=")[1] && video.split("https://www.youtube.com/watch?v=")[1];
      return `https://www.youtube.com/embed/${formattedVideo ? formattedVideo : video}?modestbranding=1&rel=0`;
    }

    //set video path
    let videoPath = this.props.sessionData.data.feature.video ? formatVideo(this.props.sessionData.data.feature.video) : undefined;

    return (
      <div className="rtrView rtrViewHide rtrHome" id="rtrHome">
        <div className="rtrContent">
          <div className="rtrHomeHeader">
            <RTRLogo />
            <RTRTimer />
            <DelayLink className="rtrButton rtrButtonHelp" to="/Help" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrHome")}></DelayLink>
          </div>
          <div className="rtrHomeRow">
            <div className="rtrHomeCol left">
              <h1>THE BIG QUESTION</h1>
              {videoPath ? <iframe title="Featured Questie video" className="rtrVideo" src={videoPath} allowFullScreen frameBorder="0"></iframe> : <div className="rtrVideo"> </div> }
              <div className="rtrBigQuestion">
                <DelayLink className="rtrButtonNull" to="/Quest" timer={RTRConst.FADE_TIME} onClick={
                  () => {RTRAct.viewFadeOut("rtrHome"); this.props.getQuest(featuredQuest.id); this.props.setQuestType(featuredAnswered, true);}
                  }>
                  <span className="rtrButtonSideIcon rtrButton rtrButtonFeatured rtrButtonTeal">
                    <i>
                      <RTRCatIcon category={featuredQuest.categories[0].id} title={featuredQuest.categories[0].title} />
                    </i>
                    <span>ADD YOUR DATA</span>
                  </span>
                </DelayLink>
                <DelayLink className="rtrButton rtrButtonFeatured rtrButtonTeal" to="/Charts" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrHome")}>VIEW ALL DATA</DelayLink>
              </div>
              <DelayLink className="rtrTeamScore rtrButtonNull" to="/Stats" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrHome")}>
                <RTRScoreBlock teamID={this.props.teamID} big={true}/>
              </DelayLink>
              <DelayLink className="rtrButton" to="/Stats" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrHome")}>ROOST STATS</DelayLink>
            </div>
            <div className="rtrHomeCol right">
              <h1>QUESTIES</h1>
              <RTRHomeQuestListConnected sessionData={this.props.sessionLoaded ? this.props.sessionData : undefined} featuredAnswered={featuredAnswered}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class RTRHomeQuestList extends React.Component {

  render() {
    // get unselected quest list
    let listDisplay = <span>Loading...</span>;
    if (this.props.sessionData !== undefined) {
      let questList = RTRAct.getSessionQuests(this.props.sessionData);
      if (questList.length !== 0) {
        let featuredQuest = RTRAct.getSessionFeatured(this.props.sessionData);
        let questListDisplay = [];
        for (let i = 0; i < questList.length; ++i) {
          let clickCallback = () => {RTRAct.viewFadeOut("rtrHome"); this.props.getQuest(questList[i].id); this.props.setQuestType(false, false);};
          if (questList[i].id === featuredQuest.id)
            clickCallback = () => {RTRAct.viewFadeOut("rtrHome"); this.props.getQuest(questList[i].id); this.props.setQuestType(this.props.featuredAnswered, true);};
          // add to quest list
          questListDisplay.push(
            <RTRHomeQuestEntry questData={questList[i]} goToQuest={clickCallback} key={"quest" + i} />
          );
        }
        listDisplay = <div>{questListDisplay}</div>;
      } else {
        console.log("ERROR: Quest list has no entries");
      }
    }
    return (
      <div className="rtrHomeQuestList">
          <div className="rtrHomeQuestListOptions">
            <h2>CHOOSE A NEW QUESTIE BELOW</h2>
            {listDisplay}
          </div>
      </div>
    );
  }
}
class RTRHomeQuestEntry extends React.Component {
  render() {
    const {questData, goToQuest} = this.props;
    let catColor = (questData.categories[0].color !== undefined) ? questData.categories[0].color : RTRConst.FALLBACK_CATEGORY_COLOR;
    let catID = (questData.categories[0].id !== undefined) ? questData.categories[0].id : RTRConst.FALLBACK_CATEGORY_ID;
    let catTitle = (questData.categories[0].title !== undefined) ? questData.categories[0].title : RTRConst.FALLBACK_CATEGORY_NAME;
    return (
      <div className = "rtrHomeQuestListItem">
        <DelayLink className="rtrButtonNull rtrHomeQuestListItemButton" style={{backgroundColor: catColor}} to="/Quest" timer={RTRConst.FADE_TIME} onClick={goToQuest}>
          <span className="rtrHomeQuestListItemInner">
            <i>
              <RTRCatIcon category={catID} title={catTitle} />
            </i>
            <span>{questData.title}</span>
          </span>
        </DelayLink>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    teamID: state.teamID,
    sessionLoaded: !state.sessionLoading,
    sessionData: state.sessionData
  };
}

function mapStateToPropsList(state) {
  return {
    sessionData: state.sessionData,
  };
}

const mapDispatchToProps = {
  getQuest: (which) => (RTRAct.fetchQuestDefault(which)),
  setQuestType: (preview, featured) => (RTRAct.setQuestViewType(preview, featured)),
  getSession: () => (RTRAct.fetchSessionDefault()),
  getStats: () => (RTRAct.fetchStatsDefault()),

}

const RTRHomeQuestListConnected = connect(mapStateToPropsList, mapDispatchToProps)(RTRHomeQuestList);

export default connect(mapStateToProps, mapDispatchToProps)(RTRHome);
