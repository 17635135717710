import React from "react";
import { MemoryRouter as Router, Route, Switch, Link } from "react-router-dom";
import "./App.scss";
import ScrollToTop from "./ScrollToTop.js";
import RTRIntro from "./Intro.js";
import RTRHome from "./Home.js";
import RTRStats from "./Stats.js";
import RTRHelp from "./Help.js";
import RTRQuest from "./Quest.js";
import RTRQuestResult from "./QuestResult.js";
import RTRCharts from "./Charts.js";

class RTRApp extends React.Component {
  render() {
    return (
      <div className="rtrApp">
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={RTRIntro} />
              <Route path="/Home" component={RTRHome} />
              <Route path="/Stats" component={RTRStats} />
              <Route path="/Help" component={RTRHelp} />
              <Route path="/Quest" component={RTRQuest} />
              <Route path="/QuestResult" component={RTRQuestResult} />
              <Route path="/Charts" component={RTRCharts} />
              <Route component={RTRNotFound} />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}

class RTRNotFound extends React.Component {
  render() {
    return (
      <div className="rtrNotFound">
        <h2>Nothing here... yet.</h2>
        <br />
        <h3>
          <Link to="/Home">Go Home</Link>
        </h3>
      </div>
    );
  }
}

export default RTRApp;
