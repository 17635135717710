import React from "react";
import { withRouter } from "react-router-dom";
import {ELEMENT_ID} from "./Constants";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let element = document.getElementById(ELEMENT_ID);
      if (this.props.toYPos !== undefined) {
        window.scrollTo(0, this.props.toYPos);
      } else {
        let elementTop = element.getBoundingClientRect().top;
        if(elementTop < -10 ) {
          window.scrollTo(0, elementTop + window.scrollY - 10);
        }
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
