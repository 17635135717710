import fetch from "cross-fetch";
import * as RTRConst from "./Constants";

////// CONSTANTS

// debug
export const DEBUG_DISPATCH = "DEBUG_DISPATCH";

// team selection
export const SELECT_TEAM = "SELECT_TEAM";

// data retrieval
export const GET_SESSION = "GET_SESSION";
export const RCV_SESSION = "RCV_SESSION";
export const GET_QUEST = "GET_QUEST";
export const RCV_QUEST = "RCV_QUEST";
export const GET_STATS = "GET_STATS";
export const RCV_STATS = "RCV_STATS";
export const GET_STATS_NEWSFEED = "GET_STATS_NEWSFEED";
export const RCV_STATS_NEWSFEED = "RCV_STATS_NEWSFEED";
export const GET_STATS_TOPQUEST = "GET_STATS_TOPQUEST";
export const RCV_STATS_TOPQUEST = "RCV_STATS_TOPQUEST";
export const GET_CHART = "GET_CHART";
export const RCV_CHART = "RCV_CHART";
export const GET_CHART_LOCAL = "GET_CHART_LOCAL";
export const RCV_CHART_LOCAL = "RCV_CHART_LOCAL";

// quest submission result
export const QUEST_RESULT = "QUEST_RESULT";

// quest viewing
export const QUEST_VIEW_TYPE = "QUEST_VIEW_TYPE";

// cookie keys
export const COOKIE_TEAM = "teamPicked";
export const FEATURED_ANSWERED = "featuredAnswered";
export const COOKIE_SESSION = "cookieSession";
////// FUNCTIONS

// visual control
export function viewFadeOut(id) {
  let el = document.getElementById(id);
  if (el) {
    el.classList.add("rtrViewHide");
  } else {
    console.log("cannot fade out element not found: " + id);
  }
}
export function viewFadeIn(id) {
  let el = document.getElementById(id);
  if (el) {
    el.classList.remove("rtrViewHide");
  } else {
    console.log("cannot fade out element not found: " + id);
  }
}

// test response check
export function debugCheckServerResponse(data) {
  return {
    type: DEBUG_DISPATCH,
    data: data
  };
}


// request team selection
export function selectTeam(which) {
  return {
    type: SELECT_TEAM,
    selected: which
  };
}

// quest view control
export function setQuestViewType(isPreview, isFeatured) {
  return {
    type: QUEST_VIEW_TYPE,
    isPreview: isPreview,
    isFeatured: isFeatured
  };
}



// retrieval action bases
function requestTemplate(type, where) {
  return {
    type: type,
    location: where
  };
}
function receiveTemplate(type, where, data) {
  return {
    type: type,
    location: where,
    data: data
  };
}
// data retrieval actions
function requestSession(where) {
  return requestTemplate(GET_SESSION, where);
}
function receiveSession(where, data) {
  return receiveTemplate(RCV_SESSION, where, data);
}
function requestQuest(where) {
  return requestTemplate(GET_QUEST, where);
}
function receiveQuest(where, data) {
  return receiveTemplate(RCV_QUEST, where, data);
}
function requestStats(where) {
  return requestTemplate(GET_STATS, where);
}
function receiveStats(where, data) {
  return receiveTemplate(RCV_STATS, where, data);
}
function requestStatsNewsfeed(where) {
  return requestTemplate(GET_STATS_NEWSFEED, where);
}
function receiveStatsNewsfeed(where, data) {
  return receiveTemplate(RCV_STATS_NEWSFEED, where, data);
}
function requestStatsTopQuests(where) {
  return requestTemplate(GET_STATS_TOPQUEST, where);
}
function receiveStatsTopQuests(where, data) {
  return receiveTemplate(RCV_STATS_TOPQUEST, where, data);
}
function requestChart(where) {
  return requestTemplate(GET_CHART, where);
}
function receiveChart(where, data) {
  return receiveTemplate(RCV_CHART, where, data);
}
function requestChartLocal(where) {
  return requestTemplate(GET_CHART_LOCAL, where);
}
function receiveChartLocal(where, data) {
  return receiveTemplate(RCV_CHART_LOCAL, where, data);
}

// submission response action
function receiveSubmissionResponse(data) {
  return {
    type: QUEST_RESULT,
    data: data
  }
}

// quest data submission actions
export function sendAnswers(questId, teamId, answers, state) {
  // make object
  let sendObj = new FormData();
  sendObj.append("team", teamId);
  if (state !== undefined)
    sendObj.append("state", state);
  for (let i = 0; i < answers.length; ++i)
    sendObj.append("answers[" + answers[i].id + "]", answers[i].value);
  // send data
  return sendForm(process.env.REACT_APP_SESSION_API + "/roost/questies/" + questId + "/complete", {"Accept": "application/json"}, sendObj, receiveSubmissionResponse)
}

// call async requests
export function fetchSession(where) {
  return fetchJSON(where, requestSession, receiveSession);
}
export function fetchQuest(which) {
  return fetchJSON(which, requestQuest, receiveQuest);
}
export function fetchStats(where) {
  return fetchJSON(where, requestStats, receiveStats);
}
export function fetchStatsNewsfeed(where) {
  return fetchJSON(where, requestStatsNewsfeed, receiveStatsNewsfeed);
}
export function fetchStatsTopQuests(where) {
  return fetchJSON(where, requestStatsTopQuests, receiveStatsTopQuests);
}
export function fetchChart(where) {
  return fetchJSON(where, requestChart, receiveChart);
}
export function fetchChartLocal(where) {
  return fetchJSON(where, requestChartLocal, receiveChartLocal);
}

// defaulted call async requests (set up with live locations when possible)
export function fetchSessionDefault() {
  return fetchSession(process.env.REACT_APP_SESSION_API + "/roost/session");
}
export function fetchQuestDefault(which) {
  return fetchQuest(process.env.REACT_APP_SESSION_API + "/roost/questies/" + which);
}
export function fetchStatsDefault() {
  return fetchStats(process.env.REACT_APP_SESSION_API + "/roost/session");
}
export function fetchStatsNewsfeedDefault() {
  return fetchStatsNewsfeed(process.env.REACT_APP_SESSION_API + "/roost/stats/latest-questies");
}
export function fetchStatsTopQuestsDefault() {
  return fetchStatsTopQuests(process.env.REACT_APP_SESSION_API + "/roost/stats/popular-questies");
}
export function fetchChartDefault() {
  return fetchChart(process.env.REACT_APP_SESSION_API + "/roost/session/chart");
}
export function fetchChartLocalDefault(which) {
  return fetchChartLocal(process.env.REACT_APP_SESSION_API + "/roost/session/chart/" + which);
}

// core JSON async
export function fetchJSON(where, requestDispatch, receiveDispatch) {
  return function(dispatch) {
    dispatch(requestDispatch(where));
    return fetch(where, {cache: "no-store", headers: {"Accept": "application/json", "Cache-Control": "no-cache"}/*, redirect: "error"*/}).then(
      // check data and errors
      response => { return response.json(); },
      error => console.log("ERROR: Fetching from " + where, error)
    ).then(
      json => dispatch(receiveDispatch(where, json))
    );
  };
}

// core send async
export function sendJSON(where, client, headers, attribute, value, receiveDispatch) {
  //console.log(typeof value, value);
  return function(dispatch) {
    return fetch(where, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        clientId: client,
        attribute: attribute,
        value: value
      })
    }).then(
      // check data and errors
      response => response.json(),
      error => console.log("ERROR: Sending to " + where, error)
    ).then(
      json => dispatch(receiveDispatch(json))
    );
  }
}

// form send async
export function sendForm(where, headers, form, receiveDispatch) {
  return function(dispatch) {
    return fetch(where, {
      method: "POST",
      headers: headers,
      body: form
    }).then(
      // check data and errors
      response => response.json(),
      error => console.log("ERROR: Sending to " + where, error)
    ).then(
      json => dispatch(receiveDispatch(json))
    );
  }
}

// session data browsing
// anything in session retrieves through additional "data" component contained
export function getSessionStart(getData) {
  return /*tzDateToUnix(*/getData.data.dateStart/*)*/;
}
export function getSessionEnd(getData) {
  return /*tzDateToUnix(*/getData.data.dateEnd/*)*/;
}
export function getSessionQuests(getData) {
  // featured is already included in list
  let output = getDataArray(getData.data.questies, translateSessionQuest, () => console.log("ERROR: Could not find quest list in session"));
  //output.push(getSessionFeatured(getData));
  return output;
}
export function getSessionFeatured(getData) {
  return translateSessionQuest(getData.data.feature);
}
export function getSessionCurrent(getData) {
  return getDataField(getData.data.current, false);
}
export function getSessionID(getData) {
  return getDataField(getData.data.id, "");
}
export function getSessionMapName(getData) {
  return getDataField(getData.data.mapTitle, "");
}
export function getSessionPieName(getData) {
  return getDataField(getData.data.pieGraphTitle, "");
}
export function getSessionBarName(getData) {
  return getDataField(getData.data.barGraphTitle, "");
}
// stats data browsing
export function getTopPlayers(data) {
  return getDataArray(data.topPlayers, translateStatsTopPlayer, () => console.log("No data found for top players in stats"));
}
export function getTopQuests(data) {
  if (data) {
    return getDataArray(data.questies, translateStatsTopQuest, () => console.log("No data found for top quests in stats"));
  } else {
    return [];
  }
}
export function getNewsFeed(data) {
  if (data) {
    return getDataArray(data.questies, translateStatsNewsItem, () => console.log("No data found for newsfeed in stats"));
  } else {
    return [];
  }
}
export function getTeamScores(data) {
  let output = [];
  // score of each team in given order
  for (let i = 0; i < RTRConst.TEAM_LIST.length; ++i) {
    output.push(getDataByID(data.teams, RTRConst.TEAM_LIST[i], "points", 0, () => console.log("No score found for team " + RTRConst.TEAM_LIST[i] + " (" + RTRConst.TEAM_NAMES[i] + ")")));
  }
 return output;
}
// template data array getter
function getDataArray(dataTarget, translator, doOnError) {
  let output = [];
  if (dataTarget !== undefined) {
    for (let i = 0; i < dataTarget.length; ++i) {
      output.push(translator(dataTarget[i]));
    }
  } else {
    if (doOnError !== undefined) {
      doOnError();
    }
  }
  return output;
}
// template generic data getter
function getDataField(dataTarget, defaultValue, doOnError) {
  // return if found
  if (dataTarget !== undefined) {
    return dataTarget
  }
  // error if not found
  if (doOnError !== undefined) {
    doOnError();
  }
  return defaultValue;
}
// template data getter by id parameter
function getDataByID(dataTarget, idValue, paramName, defaultValue, doOnError) {
  // search for data
  if (dataTarget !== undefined) {
    for (let i = 0; i < dataTarget.length; ++i) {
      if (dataTarget[i].id === idValue) {
        if (dataTarget[i][paramName] !== undefined) {
          return dataTarget[i][paramName];
        }
      }
    }
  }
  // error if not found
  if (doOnError !== undefined) {
    doOnError();
  }
  return defaultValue;
}
// session data conversion (in case format changes, change things here)
function translateSessionQuest(data) {
  // category list
  let categories = [];
  //for (let i = 0; i < data.category.length; ++i) {
    categories.push({
      id: data.category.id,
      title: data.category.title,
      color: data.category.color
    });
  //}
  // output object
  return {
    id: data.id,
    title: data.title,
    categories: categories
  };
}
// stats data conversion
function translateStatsTopPlayer(data) {
  // output object
  return {
    name: data.name,
    team: data.team,
    score: data.score
  }
}
function translateStatsTopQuest(data) {
  // output object
  return {
    title: data.questie.title,
    color: data.questie.category.color,
    score: data.tally
  }
}
function translateStatsNewsItem(data) {
  // output object
  return {
    quest: data.questie.title,
    color: data.questie.category.color,
    player: ""//data.player
  }
}
// quest data conversion
export function getQuestData(getData) {
  // category list
  let categories = [];
    categories.push({
      id: getData.data.category.id,
      title: getData.data.category.title,
      color: getData.data.category.color
    });
  // TO DO: video list
  let videos = undefined;
  // question list
  let questions = [];
  for (let i = 0; i < getData.data.questions.length; ++i) {
    // answer list
    // TO DO: consider other answer types
    let answers = [];
    for (let j = 0; j < 10; ++j) {
      let answer = getData.data.questions[i].answers[j];
      if (answer !== undefined && answer !== null && answer !== "") {
        answers.push(answer);
      }
    }
    questions.push({
      id: getData.data.questions[i].id,
      question: getData.data.questions[i].question,
      answers: answers
    });
  }
  // output object
  return {
    id: getData.data.id,
    title: getData.data.title,
    description: getData.data.description,
    categories: categories,
    photoUpload: getData.data.allowPhotoUpload,
    videos: videos,
    videoTitle: getData.data.videoTitle,
    questions: questions
  }
}

// Unix conversion to UTC string
export function unixDateToUTC(seconds) {
  return new Date(seconds * 1000).toUTCString();
}

// cookie management
// retrieve whole string
export function getCookieString() {
  // reading this gets all cookies in a string yet setting it only sets one for some reason
  return document.cookie;
}
// retrieve string organized into list
export function getCookieList() {
  // get and split cookie values
  let cStr = getCookieString();
  // try split with then without space
  let cSplit = cStr.split("; ");
  if (cSplit.length === 1) {
    cSplit = cStr.split(";");
  }
  // organize into list
  let cList = [];
  for (let i = 0; i < cSplit.length; ++i) {
    // split between first equals for key and value assignment
    let eq = cSplit[i].indexOf("=");
    if (eq > -1) {
      let key = cSplit[i].substr(0, eq);
      let val = cSplit[i].substr(eq + 1);
      cList.push({
        key: key,
        val: val
      });
    }
  }
  return cList;
}
// get value from organized list
export function getCookieValueFromList(list, key) {
  if (list !== undefined && key !== undefined) {
    // search list for key
    for (let i = 0; i < list.length; ++i) {
      if (list[i].key === key) {
        return list[i].val;
      }
    }
  }
  // not found
  return undefined;
}
// get index from organized list
export function getCookieKeyFromList(list, key) {
  if (list !== undefined && key !== undefined) {
    // search list for key
    for (let i = 0; i < list.length; ++i) {
      if (list[i].key === key) {
        return list[i].key;
      }
    }
  }
  // not found
  return undefined;
}
// set cookie with optional expire UTC string and timeout age in seconds
export function setCookie(key, val, expire, age) {
  let cStr = key + "=" + val;
  if (expire !== undefined) {
    cStr += "; expires=" + expire
  }
  if (age !== undefined) {
    cStr += "; max-age=" + age;
  }

  // setting single cookie just works this way yet it returns all of them if read for some reason
  document.cookie = cStr;
}
// remove cookie data
export function clearCookie(key) {
  // clear value and immediately expire to be sure
  document.cookie = key + "=; max-age=0";
}
// remove all cookie data
export function clearAllCookies() {
  let cList = getCookieList();
  for (let i = 0; i < cList.length; ++i) {
    clearCookie(cList[i].key);
  }
}
