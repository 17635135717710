import React from "react";
import { connect } from "react-redux";
import { CountUp } from "countup.js";
import "./Quest.scss";
import DelayLink from "./DelayLink.js";
import RTRCatIcon from "./CatIcon.js";
import * as RTRConst from "./Constants.js";
import * as RTRAct from "./Actions.js";

class RTRQuestResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {overrideLoadTimeout: false, loadedOnce: false, showPopup: false};
  }

  componentDidMount() {
    if (!this.props.resultLoaded) {
      // timeout to show app if not able to load
      this.timeoutID = setTimeout(() => this.setState({overrideLoadTimeout: true}), 1000);
      // check when loaded
      this.loadTimerID = setInterval(() => this.loadTick(), 100);
      this.scoreChecker = setInterval(() => this.checkForScore(), 100);
    } else {
      this.doFadeIn();
      setTimeout(() => this.doAnimateScore(), RTRConst.FADE_TIME);
    }
    // set submission cookie
    if (this.props.questFeatured) {
      let data = RTRAct.getQuestData(this.props.questData);
      RTRAct.setCookie(RTRAct.FEATURED_ANSWERED, data.id, this.props.sessionEndStr, RTRConst.COOKIE_FEATURED_TIMEOUT);
    }
  }

  componentWillUnmount() {
    clearInterval(this.loadTimerID);
    clearTimeout(this.timeoutID);
    clearInterval(this.scoreChecker);
  }

  loadTick() {
    // fade in when ready
    if (this.props.questResultUpdated || this.state.overrideLoadTimeout) {
      this.doFadeIn();
      // end timer
      clearInterval(this.loadTimerID);
    }
  }

  checkForScore() {
    if (this.props.resultLoaded && this.state.loadedOnce) {
      clearInterval(this.scoreChecker);
      setTimeout(() => this.doAnimateScore(), RTRConst.FADE_TIME);
    }
  }

  doAnimateScore() {
    let counter = new CountUp("rtrScoreNumber", this.props.result.points);
    counter.start();
  }

  doFadeIn() {
    // only fade in once
    if (!this.state.loadedOnce) {
      RTRAct.viewFadeIn("rtrQuestResult");
      this.setState({loadedOnce: true});
    }
  }

  doShowPopup() {
    // show popup and fade in background
    this.setState({ showPopup: true });
    setTimeout(() => RTRAct.viewFadeIn("rtrPopup"), RTRConst.FADE_TIME);
    setTimeout(() => RTRAct.viewFadeIn("rtrSubPopup"), RTRConst.FADE_TIME * 2);
  }

  render() {
    // get quest data
    let showResult = "Loading...";
    let showPopup = "";
    if (this.props.resultLoaded) {
      let resultQuest = this.props.result.questie;
      let showTitle = resultQuest.title;
      let catID = resultQuest.category.id;
      let catColor = (resultQuest.category.color !== undefined) ? resultQuest.category.color : RTRConst.FALLBACK_CATEGORY_COLOR; //(catID !== undefined) ? catID.charAt(0).toUpperCase() + catID.slice(1) : "Gray";
      let description = (resultQuest.description) ? <div className={"rtrQuestDesc"}>{resultQuest.description}</div> : "";
      //let categoryText = "rtrCatCol" + catColor;
      //let categoryBG = "rtrCatBG" + catColor;
      showResult = (
        <div>
          <div className="rtrMobileHeader">
            <h3>{showTitle}</h3>
          </div>
          <div className="rtrQuestBlock" key="initialBlock">
            <RTRCatIcon category={catID} big={true}/>
            <h1 className={"rtrDesktopHeader "/* + categoryText*/} style={{color: catColor}}>{showTitle}</h1>
           {description}
            <div className={"rtrScoreCircle " /*+ categoryBG*/} style={{backgroundColor: catColor}}>
              <div id="rtrScoreNumber">
                0
              </div>
              <div>POINTS EARNED</div>
            </div>
          </div>
        </div>
      );
      showPopup = (this.state.showPopup) ? <RTRQuestResultPopup category={catID} color={catColor} /> : "";
    }
    let exitButton = (!this.props.questFeatured)
                     ? <DelayLink className="rtrButton rtrButtonX" to="/Home" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrQuestResult")}></DelayLink>
                     : <button className="rtrButton rtrButtonX" onClick={() => this.doShowPopup()}></button>
    return (
      <div className="rtrView rtrViewHide rtrQuest" id="rtrQuestResult">
        <div className="rtrContent">
          {showResult}
          {exitButton}
          {showPopup}
        </div>
      </div>
    );
  }
}

class RTRQuestResultPopup extends React.Component {
  doFadeOut() {
    RTRAct.viewFadeOut("rtrSubPopup");
    RTRAct.viewFadeOut("rtrQuestResult");
  }

  render() {
    const { category, color } = this.props;
    return (
      <div className="rtrScoreDonePopupBG rtrViewHide" id="rtrPopup">
        <div className="rtrScoreDonePopup rtrViewHide" id="rtrSubPopup">
          <RTRCatIcon category={category} big={true}/>
          <h1 style={{color: color}}>GREAT WORK!</h1>
          <div className="rtrScoreDonePopupMiddle" style={{backgroundColor: color, color: "white"}}>Come back tomorrow to add data and earn more points!</div>
          <DelayLink className="rtrButton" to="/Home" timer={RTRConst.FADE_TIME} onClick={() => this.doFadeOut()}>Continue</DelayLink>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    result: state.questResultData,
    resultLoaded: state.questResultUpdated,
    team: state.teamID,
    questData: state.questLoadedData,
    questFeatured: state.questViewedIsFeatured,
    sessionEndStr: state.sessionEndAsString
  };
}

export default connect(mapStateToProps)(RTRQuestResult);
