import React from "react";
import { connect } from "react-redux";
import "./Timer.scss";

class RTRTimer extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {curUTC: Math.floor(Date.now() / 1000)};
  // }

  componentDidMount() {
    //this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    //clearInterval(this.timerID);
  }

  // tick() {
  //   this.setState({curUTC: Math.floor(Date.now() / 1000)});
  // }

  render() {
    // get open/close dates
    const { tilOpen, tilClose, current } = this.props;
    // compare to now to see which is sooner and not yet passed
    let timeToUse = (current < tilOpen)? tilOpen : tilClose;
    // duration left
    let timeDiff = Math.max(0, timeToUse - current);

    // convert to units
    let days = addLeadingZero(Math.floor(timeDiff / 86400));
    let hours = addLeadingZero(Math.floor((timeDiff - (days * 86400)) / 3600));
    let minutes = addLeadingZero(Math.floor((timeDiff - (days * 86400) - (hours * 3600)) / 60));
    return (
      <div className="rtrTimer">
        <div className="rtrTimerSegment">
          <div className="rtrTimerNumber">{days}</div>
          <div className="rtrTimerLabel">DAY{appendPluralS(days)}</div>
        </div>
        <div className="rtrTimerSegment">
          <div className="rtrTimerNumber">{hours}</div>
          <div className="rtrTimerLabel">HOUR{appendPluralS(hours)}</div>
        </div>
        <div className="rtrTimerSegment">
          <div className="rtrTimerNumber">{minutes}</div>
          <div className="rtrTimerLabel">MINUTE{appendPluralS(minutes)}</div>
        </div>
      </div>
    );
  }
}

function addLeadingZero(input) {
  return (input < 10 ? "0" + input : input);
}

function appendPluralS(input) {
  if (input === "01") {
    return "";
  } else {
    return "S";
  }
}

function mapStateToProps(state) {
  return {
    tilOpen: state.sessionStart,
    tilClose: state.sessionEnd,
    current: state.sessionUTC
  };
}

export default connect(mapStateToProps)(RTRTimer);
