import React from "react";
import { connect } from "react-redux";
import "./Stats.scss";
import DelayLink from "./DelayLink.js";
import VertCenterText from "./VertCenterText.js";
import RTRScoreBlock from "./ScoreBlock.js";
import * as RTRConst from "./Constants.js";
import * as RTRAct from "./Actions.js";
//import buttonTabLeft from "./img/arrow-left.png";
//import buttonTabRight from "./img/arrow-right.png";

class RTRStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {currentTab: RTRConst.TAB_NEWS};
  }

  componentDidMount() {
    setTimeout(() => RTRAct.viewFadeIn("rtrStats"), RTRConst.FADE_TIME);
    // start with default tab open
    //this.fadeCurrentTab(false);
    document.getElementById("rtrStatsNews").classList.remove("rtrStatsHidden");
    this.slidingLeft = false;
    // TO DO: reload stats if outdated
    this.props.getFeed();
    this.props.getTopQuests();
  }

  componentWillUnmount() {
    clearTimeout(this.fadeTimerID);
  }

  startCycleCategory(forward) {
    this.slidingLeft = forward;
    this.fadeCurrentTab(true);
    this.fadeTimerID = setTimeout(() => this.cycleCategory(forward), RTRConst.SLIDE_TIME);
  }

  fadeCurrentTab(out) {
    let tabToFade = undefined;
    switch(this.state.currentTab) {
      // case RTRConst.TAB_PLAYERS:
      //   tabToFade = "rtrStatsPlayers";
      //   break;
      case RTRConst.TAB_QUESTS:
        tabToFade = "rtrStatsQuests";
        break;
      case RTRConst.TAB_NEWS:
        tabToFade = "rtrStatsNews";
        break;
      default:
        break;
    }
    if (tabToFade !== undefined) {
      if (out) {
        // slide to out direction
        let slide = (this.slidingLeft) ? "rtrStatsTableLeft" : "rtrStatsTableRight";
        document.getElementById(tabToFade).classList.add(slide);
      } else {
        // remove possible previous slide
        let antiSlide = (this.slidingLeft) ? "rtrStatsTableLeft" : "rtrStatsTableRight";
        document.getElementById(tabToFade).classList.remove(antiSlide);
        // slide from in direction
        let slide = (this.slidingLeft) ? "rtrStatsTableRight" : "rtrStatsTableLeft";
        document.getElementById(tabToFade).classList.add(slide);
        document.getElementById(tabToFade).classList.remove("rtrStatsHidden");
        // slight delay so transition actually shows
        this.fadeTimerID = setTimeout(() => document.getElementById(tabToFade).classList.remove(slide), 10);
      }
    }
  }

  cycleCategory(forward) {
    if (forward) {
      let newTab = (this.state.currentTab + 1) % RTRConst.TAB_ALL;
      this.setState({currentTab: newTab});
    } else {
      let newTab = this.state.currentTab - 1;
      if (newTab < 0) {
        newTab = RTRConst.TAB_ALL - 1;
      }
      this.setState({currentTab: newTab});
    }
    this.fadeCurrentTab(false);
  }

  render() {
    // get all stats
    const { feed, topquests } = this.props;
    // set up lists
    //let playerData = RTRAct.getTopPlayers(data);
    let questData = RTRAct.getTopQuests(topquests);
    let newsData = RTRAct.getNewsFeed(feed);
    return (
      <div className="rtrView rtrViewHide rtrStats" id="rtrStats">
        <div className="rtrContent">
          <div className="rtrMobileHeader">
            <h3>Stats</h3>
          </div>
          <div className="rtrStatsHeader">
            <div className="rtrStatsHeaderSub rtrTopBottomHeader">
              <RTRScoreBlock teamID={RTRConst.TEAM_PURPLE} />
              <RTRScoreBlock teamID={RTRConst.TEAM_YELLOW} />
              <RTRScoreBlock teamID={RTRConst.TEAM_RED} />
              <RTRScoreBlock teamID={RTRConst.TEAM_GREEN} />
              <DelayLink className="rtrButton rtrButtonX rtrButtonXInvert" to="/Home" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrStats")}></DelayLink>
            </div>
          </div>
          <RTRStatsNews dataList={newsData} active={(this.state.currentTab === RTRConst.TAB_NEWS)}/>
          <RTRStatsQuests dataList={questData} active={(this.state.currentTab === RTRConst.TAB_QUESTS)}/>
          <button title="Previous" className="rtrButtonTab rtrButtonTabLeft" onClick={() => this.startCycleCategory(false)}>
          </button>
          <button title="Next" className="rtrButtonTab rtrButtonTabRight" onClick={() => this.startCycleCategory(true)}>
          </button>
        </div>
      </div>
    );
  }
}

// class RTRStatsPlayers extends React.Component {
//   render() {
//     const {dataList} = this.props;
//     let output = <h2 className="rtrStatsPlaceholder">{RTRConst.NO_DATA_MSG}</h2>;
//     // check list
//     if (dataList !== undefined && dataList.length > 0) {
//       let listCountMax = Math.min((isWidthMobile() ? RTRConst.STATS_LIST_MAX_MOBILE : RTRConst.STATS_LIST_MAX_DESKTOP), dataList.length);
//       let outList = [];
//       for (let i = 0; i < listCountMax; ++i) {
//         outList.push(
//           <div className="rtrStatsEntry" key={"playerEntry" + i}>
//             <div className={"rtrStatsEntryName rtrTeamBG" + dataList[i].team}>
//               {dataList[i].name}
//             </div>
//             <div className={"rtrStatsEntryValue rtrTeamCol" + dataList[i].team}>
//               {printNumberWithCommas(dataList[i].score)}
//             </div>
//           </div>
//         );
//       }
//       output = <div className="rtrStatsEntries">{outList}</div>;
//     }
//     return (
//       <div className={"rtrStatsTable" + (!this.props.active ? " rtrStatsHidden" : "")} id="rtrStatsPlayers">
//         <h1>TOP PLAYERS</h1>
//         {output}
//       </div>
//     );
//   }
// }

class RTRStatsQuests extends React.Component {
  render() {
    const {dataList} = this.props;
    let output = <h2 className="rtrStatsPlaceholder">{RTRConst.NO_DATA_MSG}</h2>;
    // check list
    if (dataList !== undefined && dataList.length > 0) {
      let listCountMax = Math.min((isWidthMobile() ? RTRConst.STATS_LIST_MAX_MOBILE : RTRConst.STATS_LIST_MAX_DESKTOP), dataList.length);
      let outList = [];
      for (let i = 0; i < listCountMax; ++i) {
        let dataColor = (dataList[i].color !== undefined ? dataList[i].color : RTRConst.FALLBACK_CATEGORY_COLOR);
        outList.push(
          <div className="rtrStatsEntry" key={"questEntry" + i}>
            <div className="rtrStatsEntryName" style={{backgroundColor: dataColor}}>
              {dataList[i].title}
            </div>
            <div className="rtrStatsEntryValue" style={{color: dataColor}}>
              <VertCenterText alignRight={true}>{printNumberWithCommas(dataList[i].score)}</VertCenterText>
            </div>
          </div>
        );
      }
      output = <div className="rtrStatsEntries">{outList}</div>;
    }
    return (
      <div className={"rtrStatsTable" + (!this.props.active ? " rtrStatsHidden" : "")} id="rtrStatsQuests">
        <h1>POPULAR QUESTIES</h1>
        {output}
      </div>
    );
  }
}

class RTRStatsNews extends React.Component {
  render() {
    const {dataList} = this.props;
    let output = <h2 className="rtrStatsPlaceholder">{RTRConst.NO_DATA_MSG}</h2>;
    // check list
    if (dataList !== undefined && dataList.length > 0) {
      let listCountMax = Math.min((isWidthMobile() ? RTRConst.STATS_LIST_MAX_MOBILE : RTRConst.STATS_LIST_MAX_DESKTOP), dataList.length);
      let outList = [];
      for (let i = 0; i < listCountMax; ++i) {
        let dataColor = (dataList[i].color !== undefined ? dataList[i].color : RTRConst.FALLBACK_CATEGORY_COLOR);
        outList.push(
          <div className="rtrStatsEntry" key={"questEntry" + i} style={{backgroundColor: dataColor}}>
            <div className="rtrStatsEntryName" style={{backgroundColor: dataColor, width: "100%"}}>
              {dataList[i].quest}
            </div>
          </div>
        );
      }
      output = <div className="rtrStatsEntries">{outList}</div>;
    }
    return (
      <div className={"rtrStatsTable" + (!this.props.active ? " rtrStatsHidden" : "")} id="rtrStatsNews">
        <h1>NEWSFEED</h1>
        {output}
      </div>
    );
  }
}

function isWidthMobile() {
  return (window.innerWidth < RTRConst.WIDTH_MOBILE_LIMIT);
}

// found here: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
// should work since score numbers shouldn't have any decimals in this
function printNumberWithCommas(input) {
  if (input !== undefined) {
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "undefined";
  }
}

function mapStateToProps(state) {
  return {
    data: state.statsData,
    feed: state.statsNewsfeed,
    topquests: state.statsTopQuests
  };
}

const mapDispatchToProps = {
  getFeed: () => (RTRAct.fetchStatsNewsfeedDefault()),
  getTopQuests: () => (RTRAct.fetchStatsTopQuestsDefault()),
}

export default connect(mapStateToProps, mapDispatchToProps)(RTRStats);
