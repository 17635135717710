import React from "react";
import {Howl} from "howler";
import "./Help.scss";
import DelayLink from "./DelayLink.js";
import * as RTRConst from "./Constants.js";
import * as RTRAct from "./Actions.js";

import help1 from "./img/help/1.jpg";
import help2 from "./img/help/2.jpg";
import help3 from "./img/help/3.jpg";
import help4 from "./img/help/4.jpg";
import help5 from "./img/help/5.jpg";
import help6 from "./img/help/6.jpg";
import help7 from "./img/help/7.jpg";

import helpVO1 from  "./sound/help-1.mp3";
import helpVO2 from  "./sound/help-2.mp3";
import helpVO3 from  "./sound/help-3.mp3";
import helpVO4 from  "./sound/help-4.mp3";
import helpVO5 from  "./sound/help-5.mp3";
import helpVO6 from  "./sound/help-6.mp3";
import helpVO7 from  "./sound/help-7.mp3";

class RTRHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {slide: 0, maxSlide: 7, isFading: false};
    
    this.sounds = [
      this.vo1 = new Howl({
        src: [helpVO1],
      }),

      this.vo2 = new Howl({
        src: [helpVO2],
      }),

      this.vo3 = new Howl({
        src: [helpVO3],
      }),

      this.vo4 = new Howl({
        src: [helpVO4],
      }),
      
      this.vo5 = new Howl({
        src: [helpVO5],
      }),
      
      this.vo6 = new Howl({
        src: [helpVO6],
      }),  

      this.vo7 = new Howl({
        src: [helpVO7],
      }),
    ]
  }

  componentDidMount() {
    setTimeout(function() {
      RTRAct.viewFadeIn("rtrHelp");
      this.playSlideAudio(0);
    }.bind(this), RTRConst.FADE_TIME);
  }

  componentWillUnmount() {
    clearTimeout(this.voDelayTimer);
    this.stopAllSound();
  }

  toPrevSlide() {
    let prevSlide = this.state.slide - 1;
    if (prevSlide < 0) {
        prevSlide = 0;
    }
    this.setState({slide: prevSlide});
    this.playSlideAudio(prevSlide);
  }

  toNextSlide() {
    let nextSlide = this.state.slide + 1;
    if (nextSlide >= this.state.maxSlide) {
        nextSlide = this.state.maxSlide - 1;
    }
    this.setState({slide: nextSlide});
    this.playSlideAudio(nextSlide);
  }

  fadeToPrevSlide() {
    if (!this.state.isFading) {
      this.setState({isFading: true});
      setTimeout(function() {this.setState({isFading: false}); this.toPrevSlide();}.bind(this), RTRConst.FADE_TIME);
    }
  }

  fadeToNextSlide() {
    if (!this.state.isFading) {
      this.setState({isFading: true});
      setTimeout(function() {this.setState({isFading: false}); this.toNextSlide();}.bind(this), RTRConst.FADE_TIME);
    }
  }

  playSlideAudio(which) {
    this.voDelayTimer = setTimeout(function() {
      this.stopAllSound();
      this.sounds[which] && this.sounds[which].play();
    }.bind(this), RTRConst.VO_DELAY);
  }

  stopAllSound() {
    this.sounds.forEach((sound)=>{
      sound.stop();
    })
  }

  render() {
    return (
      <div className="rtrView rtrViewHide rtrHelp" id="rtrHelp">
        <div className="rtrContent">
          <div className="rtrMobileHeader">
            <h3>Help</h3>
          </div>
          <div className="rtrHelpWrapper">
            <div className={"rtrHelpBox" + ((this.state.isFading) ? " rtrHelpBoxFade" : "")}>
              <RTRHelpSlide slide={this.state.slide} />
              <div className="rtrHelpActions">
                {(this.state.slide > 0) ? <button className="rtrButton" onClick={() => this.fadeToPrevSlide()}>Back</button> : ""}
                {(this.state.slide < this.state.maxSlide - 1)
                  ? <button className="rtrButton" onClick={() => this.fadeToNextSlide()}>Next</button>
                  : <DelayLink className="rtrButton" to="/Home" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrHelp")}>Done</DelayLink>}
              </div>
            </div>
            <RTRHelpDots current={this.state.slide} total={this.state.maxSlide} />
            <DelayLink className="rtrButton rtrButtonX" to="/Home" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrHelp")}></DelayLink>
          </div>
        </div>
      </div>
    );
  }
}

class RTRHelpSlide extends React.Component {

  constructor(props) {
    super(props);
      this.state = {images: [help1, help2, help3, help4, help5, help6, help7]};
    };

  render() {
    const {slide} = this.props;
    let slideText;

    switch (slide) {
      case 0:
      default:
        slideText = <h2>Play with other SciGirls to earn points for your team! Which team will have the most points when time runs out?</h2>;
        break;
      case 1:
        slideText = <h2>Will you help answer <em>The Big Question</em> or complete a <em>Questie</em>?</h2>;
        break;
      case 2:
        slideText = <h2>Choose the <em>Questie</em> you will work on and favorite <em>Questies</em> you want to do next. You can change your <em>Questie</em>.</h2>;
        break;
      case 3:
        slideText = <h2><em>Add Your Data</em> to help answer <em>The Big Question</em> every day. <em>See All Data</em> to look at how SciGirls everywhere are helping too.</h2>;
        break;
      case 4:
        slideText = <h2>Whenever you help with <em>The Big Question</em> or finish a <em>Questie</em>, you earn points for your team and level up your skills. Higher levels mean more points so keep playing!</h2>;
        break;
      case 5:
        slideText = <h2>Look at <em>Roost Stats</em> to see your team's score and what other SciGirls are doing.</h2>;
        break;
      case 6:
        slideText = <h2>Don't forget that everything you do in the game adds to your SciGirls profile! What kind of SciGirl are you?</h2>;
        break;
    }
    return (
      <div className="rtrHelpSlide">
          {this.state.images.map((image, index) => (
            <img 
                key={index} src={image} 
                alt={"Slide " + (slide + 1) + " Image"} 
                style={{display: index !== slide && "none"}}
            />
          ))}
        <br />
        {slideText}
      </div>
    );
  }
}

class RTRHelpDots extends React.Component {
  render() {
    const {current, total} = this.props;
    let dots = [];
    for (let i = 0; i < total; ++i) {
        if (i === current) {
            dots.push(<span className="rtrDotOn" key={"dots" + i}></span>);
        } else {
            dots.push(<span key={"dots" + i}></span>);
        }
    }
    return (
      <div className="rtrHelpDots">
        {dots}
      </div>
    )
  }
}

export default RTRHelp;
