import React from "react";
import { connect } from "react-redux";
import "./Charts.scss";
import DelayLink from "./DelayLink.js";
import * as RTRConst from "./Constants.js";
import * as RTRAct from "./Actions.js";
//import logo from "./img/logo.svg";
import {GoogleCharts} from 'google-charts';

class RTRCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {mapDrawn: false, currentState: undefined, showingStateView: false, showingStateCloser: false};
  }

  componentDidMount() {
    setTimeout(() => RTRAct.viewFadeIn("rtrCharts"), RTRConst.FADE_TIME);
    this.props.getMapData();
    // bind here to allow "this"
    this.geoChartStateClick = this.geoChartStateClick.bind(this);
    this.closeStateView = this.closeStateView.bind(this);
    this.closeCloserView = this.closeCloserView.bind(this);
    this.openCloserView = this.openCloserView.bind(this);

    window.addEventListener('resize', this.handleWindowResize.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize.bind(this));
  }

  handleWindowResize() {
      this.drawGeoChart();
  }

  render() {
    // set chart view
    let chartTitle = "Loading...";
    let showContent = (
      <div>
        <div id="rtrMapChart"></div>
        <h3>Click on a state to see more data</h3>
      </div>
    );
    // TO DO: determine which question shows on pie chart specifically
    let pieChartQuestion = (this.props.mapData !== undefined) ? this.props.mapData.questie.questions[0].id : 0;
    // for any state views
    if (this.state.showingStateCloser) {
      chartTitle = this.props.sessionData.data.barGraphTitle.replace("{{state}}", this.state.currentState.toUpperCase());
      // show closer state view
      showContent = <RTRChartCloser loading={this.props.localLoading} data={this.props.localData} backButton={() => this.closeCloserView()}></RTRChartCloser>
    } else if (this.state.showingStateView) {
      chartTitle = this.props.sessionData.data.pieGraphTitle.replace("{{state}}", this.state.currentState.toUpperCase());
      // show state view
      showContent = <RTRChartState currentState={this.state.currentState} questionId={pieChartQuestion} data={this.props.mapData} backButton={() => this.closeStateView()} forwardButton={() => this.openCloserView()}></RTRChartState>
    } else {
      // draw map chart if not drawn
      chartTitle = this.props.sessionData.data.mapTitle;
      if (!this.props.mapLoading && this.props.mapData !== undefined && !this.state.mapDrawn) {
        GoogleCharts.load(() => this.drawGeoChart(), { 'packages':['geochart']/*, 'mapsApiKey': 'YOUR_API_KEY'*/ }); // may not need API for this chart
      }
    }
    // draw current view
    return (
      <div className="rtrView rtrViewHide rtrCharts" id="rtrCharts">
        <div className="rtrContent">
          <h2>{chartTitle}</h2>
          {showContent}
          <DelayLink className="rtrButton rtrButtonX" to="/Home" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrCharts")}></DelayLink>
        </div>
      </div>
    );
  }

  drawGeoChart() {
    // set up quest data to show total responses in map view
    let dataArray = [];
    let questionIndex = 0;
    let questionID = this.props.mapData.questie.questions[questionIndex].id;
    // build initial row
    dataArray.push(['State', 'Responses']);
    // get data for chosen control question
    let questionData = undefined;
    for (let i = 0; i < this.props.mapData.chart.length; ++i) {
      if (this.props.mapData.chart[i].questionId === questionID) {
        questionData = this.props.mapData.chart[i].states;
        break;
      }
    }
    // set total response count data
    if (questionData !== undefined) {
      for (let i = 0; i < RTRConst.US_STATES_SHORT.length; ++i) {
        let stateData = questionData[RTRConst.US_STATES_SHORT[i]];
        if (stateData !== undefined)
        {
          let rTotal = 0;
          for (let j = 0; j < stateData.length; ++j)
            rTotal += stateData[j];
          dataArray.push([RTRConst.US_STATES[i], rTotal]);
        }
      }
    }

    const geo_data = GoogleCharts.api.visualization.arrayToDataTable(dataArray);
    // display options
    const geo_options = {
      region: 'US',
      resolution: 'provinces',
      legend: {
        numberFormat:'###,###'
      },
      colorAxis: {
        minValue: 0,
        colors: [
          "#fec",
          "#fc0",
          "#f90"
        ]
      },
      backgroundColor: {
        fill: 'transparent'
      }
    }
    // build chart with events
    const geo_chart = new GoogleCharts.api.visualization.GeoChart(document.getElementById('rtrMapChart'));
    GoogleCharts.api.visualization.events.addListener(geo_chart, 'regionClick', this.geoChartStateClick);
    geo_chart.draw(geo_data, geo_options);
    this.setState({mapDrawn: true});
  }

  geoChartStateClick(region) {
    // open pie chart for state
    let stateCode = region.region.substr(3, 2).toLowerCase();

    let hasResults = false;
    if(this.props.mapData.chart) {
        let stateResults = this.props.mapData.chart[0].states[stateCode];

        hasResults = stateResults.reduce((previous, current) => {
            return (previous) ? true : current;
        })
    }

    if(hasResults) {
        this.props.getLocalData(stateCode);
        this.setState({showingStateView: true, currentState: stateCode, mapDrawn: false});
    }
  }

  openCloserView() {
    this.setState({showingStateCloser: true});
  }

  closeCloserView() {
    this.setState({showingStateCloser: false});
  }

  closeStateView() {
    this.setState({showingStateView: false});
  }
}

class RTRChartState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {pieDrawn: false};
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize.bind(this));
  }

  handleWindowResize() {
      this.drawPieChart();
  }

  render() {
    // pie chart showing statistics
    if (!this.props.currentState !== undefined && !this.state.pieDrawn) {
      GoogleCharts.load(() => this.drawPieChart(), { 'packages':['corechart'] });
    }
    return (
      <div>
        <button className="rtrButton rtrButtonBack" onClick={() => this.props.backButton()} />
        <div id="rtrPieChart"></div>
        <button className="rtrButton" onClick={() => this.props.forwardButton()}>Take a closer look</button>
      </div>
    );
  }

  drawPieChart() {
    // get data from server
    const { currentState, questionId, data } = this.props;
    let question = undefined;
    let questionData = [];
    for (let i = 0; i < data.questie.questions.length; ++i) {
      if (data.questie.questions[i].id === questionId) {
        question = data.questie.questions[i];
        break;
      }
    }
    for (let i = 0; i < data.chart.length; ++i) {
      if (data.chart[i].questionId === questionId) {
        questionData = data.chart[i].states[currentState];
        break;
      }
    }
    // organize by number of answers
    if (question !== undefined && questionData.length > 0)
    {
      let dataArray = [[question.question, "Count"]];
      for (let i = 0; i < question.answers.length; ++i) {
        dataArray.push([question.answers[i], questionData[i]]);
      }

      // display options
      const pie_options = {
        backgroundColor: {
          fill: 'transparent'
        }
      }
      // build chart
      const pie_data = GoogleCharts.api.visualization.arrayToDataTable(dataArray);
      const pie_chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('rtrPieChart'));
      pie_chart.draw(pie_data, pie_options);
      this.setState({pieDrawn: true});
    }
  }
}

class RTRChartCloser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {barDrawn: false};
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize.bind(this));
  }

  handleWindowResize() {
      this.drawBarChart();
  }

  render() {
    // TO DO: bar chart showing some statistics
    if (!this.props.loading && this.props.data !== undefined && !this.state.barDrawn) {
      GoogleCharts.load(() => this.drawBarChart(), { 'packages':['corechart'] });
    }
    return (
      <div>
        <button className="rtrButton rtrButtonBack" onClick={() => this.props.backButton()} />
        <div id="rtrBarChart"></div>
      </div>
    );
  }

  drawBarChart() {
    // get data from server
    const { data } = this.props;
    let questionGrouping = undefined;
    let questionPer = undefined;
    let questionData = data.chart;
    // TO DO: determine which axis and questions data is for specifically
    for (let i = 0; i < data.questie.questions.length; ++i) {
      if (data.questie.questions[i].answers.length === questionData.length) {
        // total data sets
        questionGrouping = data.questie.questions[i];
      } else if (data.questie.questions[i].answers.length === questionData[0].length) {
        // data per set
        questionPer = data.questie.questions[i];
      }
      // if both found, done
      if (questionPer !== undefined && questionGrouping !== undefined) {
        break;
      }
    }
    // group answers by axis
    if (questionPer !== undefined && questionGrouping !== undefined)
    {
      let dataArray = [[questionPer.question]];
      for (let i = 0; i < questionPer.answers.length; ++i) {
        dataArray[0].push(questionPer.answers[i]);
      }
      for (let i = 0; i < questionGrouping.answers.length; ++i) {
        dataArray.push([questionGrouping.answers[i]]);
        for (let j = 0; j < questionData[i].length; ++j) {
          dataArray[dataArray.length - 1].push(questionData[i][j]);
        }
      }

      // display options
      const bar_options = {
        backgroundColor: {
          fill: 'transparent'
        },
        isStacked: true,
        legend: {
          position: 'top', maxLines: 3
        },
      }
      // build chart
      const bar_data = GoogleCharts.api.visualization.arrayToDataTable(dataArray);
      const bar_chart = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('rtrBarChart'));
      bar_chart.draw(bar_data, bar_options);
      this.setState({barDrawn: true});
    }
  }
}

function mapStateToProps(state) {
  return {
    mapLoading: state.chartLoading,
    mapData: state.chartData,
    localLoading: state.chartLocalLoading,
    localData: state.chartLocalData,
    sessionData: state.sessionData
  };
}

const mapDispatchToProps = {
  getMapData: () => (RTRAct.fetchChartDefault()),
  getLocalData: (which) => (RTRAct.fetchChartLocalDefault(which)),
}

export default connect(mapStateToProps, mapDispatchToProps)(RTRCharts);
