import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./Intro.scss";
import DelayLink from "./DelayLink.js";
import RTRLogo from "./Logo.js";
import RTRTimer from "./Timer.js";
import * as RTRConst from "./Constants.js";
import * as RTRAct from "./Actions.js";
import iconGreen from "./img/icon-big/green.png";
import iconGreenActive from "./img/icon-big/green-active.png";
import iconPurple from "./img/icon-big/purple.png";
import iconPurpleActive from "./img/icon-big/purple-active.png";
import iconYellow from "./img/icon-big/yellow.png";
import iconYellowActive from "./img/icon-big/yellow-active.png";
import iconRed from "./img/icon-big/red.png";
import iconRedActive from "./img/icon-big/red-active.png";
import selectArrow from "./img/selectarrowteam.png";
class RTRIntro extends React.Component {
  constructor(props) {
    super(props);
    // get team selection state and session cookie
    let prevSession = parseInt(RTRAct.getCookieValueFromList(RTRAct.getCookieList(), RTRAct.COOKIE_SESSION));
    let prevChosenTeam = RTRAct.getCookieValueFromList(RTRAct.getCookieList(), RTRAct.COOKIE_TEAM);
    this.state = {loadedOnce: false, sessionValid: false, overrideLoadTimeout: false, pickStarted: false, pickSlowDown: false, pickFinished: false, currentHighlight: -1, prevChosenTeam: prevChosenTeam, prevSession: prevSession};
    // bind for "this"
    this.doPickTeam = this.doPickTeam.bind(this);
    this.pickSpecificTeam = this.pickSpecificTeam.bind(this);
  }

  componentDidMount() {
    // start loading session
    this.props.getSession();
    // timeout to show app if not able to load
    this.timeoutID = setTimeout(() => this.setState({overrideLoadTimeout: true}), 3000);
    // check when loaded
    this.loadTimerID = setInterval(() => this.loadTick(), 100);
    // check when validated
    this.validateTimerID = setInterval(() => this.validateSession(), 100);
  }

  componentWillUnmount() {
    const {sessionData} = this.props;

    clearInterval(this.loadTimerID);
    clearInterval(this.cycleTimerID);
    clearInterval(this.validateTimerID);
    clearTimeout(this.timeoutID);
    clearTimeout(this.pickTimerID);
    RTRAct.setCookie(RTRAct.COOKIE_TEAM, this.props.teamID, this.props.sessionEndStr);
    if(sessionData) RTRAct.setCookie(RTRAct.COOKIE_SESSION, sessionData.data.id, this.props.sessionEndStr);
  }

  loadTick() {
    // fade in when ready
    if ((this.props.sessionLoaded /*&& this.props.isLoggedIn*/) || this.state.overrideLoadTimeout) {
      this.doFadeIn();
      // end timer
      clearInterval(this.loadTimerID);
      this.loadTimerID = undefined;
    }
  }

  cycleTick() {
    // go to next in cycle
    let newHighlight = (this.state.currentHighlight + 1) % RTRConst.TEAM_LIST.length;
    this.setState({currentHighlight: newHighlight});
  }

  pickTick() {
    // minimum time done before possibly slowing down to select
    clearInterval(this.cycleTimerID);
    this.setState({pickSlowDown: true});
    this.cycleTimerID = setInterval(() => this.waitTick(), RTRConst.PICK_STARTING_DELAY);
  }

  slowTick(lastTime) {
    const {teamID} = this.props;
    // slow down gradually until delay limit reached
    if (lastTime < RTRConst.PICK_SLOWDOWN_SLOWEST) {
      this.cycleTick();
      let newTime = lastTime + RTRConst.PICK_SLOWDOWN_INCREMENT;
      this.pickTimerID = setTimeout(() => this.slowTick(newTime), newTime);
    } else {
      // continue until pick, then stop
      if (teamID !== RTRConst.TEAM_NONE && this.state.currentHighlight !== teamID) {
        this.cycleTick();
        this.pickTimerID = setTimeout(() => this.slowTick(RTRConst.PICK_SLOWDOWN_SLOWEST), RTRConst.PICK_SLOWDOWN_SLOWEST);
      } else {
        this.pickTimerID = setTimeout(() => this.finishTick(), RTRConst.PICK_SLOWDOWN_SLOWEST);
      }
    }
  }

  waitTick() {
    // wait until team has been chosen
    this.cycleTick();
    if (this.props.teamID !== RTRConst.TEAM_NONE) {
      clearInterval(this.cycleTimerID);
      this.pickTimerID = setTimeout(() => this.slowTick(RTRConst.PICK_STARTING_DELAY), RTRConst.PICK_STARTING_DELAY);
    }
  }

  finishTick() {
    // waited time until animation done
    this.setState({pickFinished: true});
  }

  doFadeIn() {
    // only fade in once
    if (!this.state.loadedOnce) {
      setTimeout(() => RTRAct.viewFadeIn("rtrIntro"), RTRConst.FADE_TIME);
      this.setState({loadedOnce: true});
    }
  }

  doPickTeam() {
    if (!this.state.pickStarted) {
      // send pick request
      this.props.selectTeam();
      // start animating
      this.setState({pickStarted: true});
      this.cycleTimerID = setInterval(() => this.cycleTick(), RTRConst.PICK_STARTING_DELAY);
      // wait minimum time before selecting
      this.pickTimerID = setTimeout(() => this.pickTick(), RTRConst.PICK_MIN_CYCLE_TIME);
    }
  }

  pickSpecificTeam(which) {
    this.props.selectTeam(RTRConst.TEAM_LIST[which]);
    this.setState({pickFinished: true, currentHighlight: which});
  }

  validateSession() {
    const {sessionData} = this.props;
    // check if session data exists (attempt to have session loaded first)
    if (sessionData !== undefined) { 

      // check for "current" flag
      if (RTRAct.getSessionCurrent(sessionData)){

        //if sessionId does not match the session id stored in the cookie, rechoose team
        if (sessionData.data.id !== this.state.prevSession) {
          RTRAct.clearCookie(RTRAct.COOKIE_TEAM);
          this.setState({prevChosenTeam: undefined})
        }

        this.setState({sessionValid: true});
        clearInterval(this.validateTimerID);
        this.validateTeamSelect();  // validate team after session 
      }
    }
  }

  validateTeamSelect() {
    // check previous team choice
    if (this.state.prevChosenTeam !== undefined) {
      let chosen = parseInt(this.state.prevChosenTeam);
      if (chosen !== RTRConst.TEAM_NONE) {
        this.props.selectTeam(chosen);
        this.setState({ skipIntro: true });
      }
    }
  }

  render() {
    // TO DO: determine session team state

      if (!this.state.sessionValid) {
        // no session
        return (
          <RTRIntroSkeleton>
            <RTRIntroNoSession />
          </RTRIntroSkeleton>
        );
      } else {
        if (this.state.skipIntro) {
          // session with team chosen
          return (
            <RTRIntroSkeleton>
              <Redirect push to={"/Home"} />
            </RTRIntroSkeleton>
          )
        } else {
          // session with no team chosen
          return (
            <RTRIntroSkeleton>
              <RTRIntroPickTeam selectTeam={this.pickSpecificTeam} animating={this.state.pickStarted} slowing={this.state.pickSlowDown} highlighted={this.state.currentHighlight} readyToGo={this.state.pickFinished}/>
              <RTRTimer />
            </RTRIntroSkeleton>
          );
        }
      }
    //}
  }
}


class RTRIntroSkeleton extends React.Component {
  render() {
    return (
      <div className="rtrView rtrViewHide rtrIntro" id="rtrIntro">
        <div className="rtrContent">
          <RTRLogo />
          {this.props.children}
        </div>
      </div>
    );
  }
}

class RTRIntroNoSession extends React.Component {
  componentDidMount() {
    // pick random icon to show
    this.pickedIcon = Math.floor(Math.random() * RTRConst.TEAM_LIST.length);
  }
  render() {
    return (
      <div className="rtrIntroNoSession">
        <RTRIntroIcon teamID={this.pickedIcon} />
        <h1>The new session hasn't started yet. Keep an eye on the countdown clock and stay tuned to play!</h1>
        <RTRTimer />
      </div>
    );
  }
}

class RTRIntroPickTeam extends React.Component {
  render() {
    const {/*animating,*/ highlighted, readyToGo} = this.props;
    let icons = [];
    for (let i = 0; i < RTRConst.TEAM_LIST.length; ++i) {
      icons.push(<RTRIntroIcon teamID={i} key={"icon" + i} highlighted={(highlighted === i)} onClick={() => this.props.selectTeam(i)}/>);
    }
    return (
      <div className="rtrIntroPickTeam">
        <h1>Pick your team!</h1>
        <div className="rtrIntroIcons">
          {icons}
        </div>
        <DelayLink className="rtrButton" to="/Home" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrIntro")} disabled={!readyToGo}>Done</DelayLink>
      </div>
    );
  }
}

class RTRIntroIcon extends React.Component {
  render() {
    const {teamID, highlighted} = this.props;
    let chosenIcon;
    switch (teamID) {
      case RTRConst.TEAM_GREEN:
        chosenIcon = (highlighted ? iconGreenActive : iconGreen);
        break;
      case RTRConst.TEAM_YELLOW:
        chosenIcon = (highlighted ? iconYellowActive : iconYellow);
        break;
      case RTRConst.TEAM_PURPLE:
        chosenIcon = (highlighted ? iconPurpleActive : iconPurple);
        break;
      case RTRConst.TEAM_RED:
        chosenIcon = (highlighted ? iconRedActive : iconRed);
        break;
      default:
        chosenIcon = "";
        break;
    }
    return (
      <div className={"rtrIntroIcon" + ((highlighted) ? " highlight" : "")} onClick={() => this.props.onClick()}>
        <img src={chosenIcon} alt={RTRConst.TEAM_NAMES[teamID]}/>
        <img className={"selectArrow" + ((highlighted) ? "" : " hidden")} src={selectArrow} alt={"Selected"}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    teamID: state.teamID,
    sessionLoaded: !state.sessionLoading,
    sessionData: state.sessionData,
    sessionStart: state.sessionStart,
    sessionEnd: state.sessionEnd,
    sessionEndStr: state.sessionEndAsString
  };
}

const mapDispatchToProps = {
  selectTeam: (which) => (RTRAct.selectTeam(which)),
  getSession: () => (RTRAct.fetchSessionDefault())
}

export default connect(mapStateToProps, mapDispatchToProps)(RTRIntro);
