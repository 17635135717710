import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./Quest.scss";
import DelayLink from "./DelayLink.js";
import RTRCatIcon from "./CatIcon.js";
import * as RTRConst from "./Constants.js";
import * as RTRAct from "./Actions.js";

class RTRQuest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loadedOnce: false, overrideLoadTimeout: false, selectedState: (this.props.questFeatured ? "1" : undefined), hasSubmitted: false, goToNextScreen: false};
    // set up chosen answer array
    this.selectedAnswers = [];
    for (let i = 0; i < RTRConst.MAX_QUESTIONS; ++i) {
      this.selectedAnswers.push(RTRConst.ANSWER_UNSELECTED);
    }
    // bind here to allow "this"
    this.doSelectAnswer = this.doSelectAnswer.bind(this);
    this.doSelectState = this.doSelectState.bind(this);
    this.doSendData = this.doSendData.bind(this);
  }

  componentDidMount() {
    // timeout to show app if not able to load
    this.timeoutID = setTimeout(() => this.setState({overrideLoadTimeout: true}), 3000);
    // check when loaded
    this.loadTimerID = setInterval(() => this.loadTick(), 100);
  }

  componentWillUnmount() {
    clearInterval(this.loadTimerID);
    clearTimeout(this.timeoutID);
  }

  loadTick() {
    // fade in when ready
    if (this.props.questLoaded || this.state.overrideLoadTimeout) {
      this.doFadeIn();
      // end timer
      clearInterval(this.loadTimerID);
    }
  }

  doFadeIn() {
    // only fade in once
    if (!this.state.loadedOnce) {
      setTimeout(() => RTRAct.viewFadeIn("rtrQuest"), RTRConst.FADE_TIME);
      this.setState({loadedOnce: true});
    }
  }

  doSelectAnswer(q, a) {
    // set answer state
    this.selectedAnswers[q] = a;
    // fade out other buttons in question
    if (this.data !== undefined) {
      for (let i = 0; i < this.data.questions[q].answers.length; ++i) {
        let buttonId = document.getElementById("answer-" + q + "-" + i);
        if (buttonId !== undefined) {
          if (i === a) {
            buttonId.classList.remove("rtrButtonFade");
          } else {
            buttonId.classList.add("rtrButtonFade");
          }
        }
      }
    }
  }

  doSelectState(event) {
    this.setState({selectedState: event.target.value});
  }

  doSendData() {
    // TO DO: consider making sure state is deliberately chosen?
    // check for unanswered questions and get existing answers
    if (this.data !== undefined) {
      let count = Math.min(RTRConst.MAX_QUESTIONS, this.data.questions.length);
      let answers = [];
      let unanswered = 0;
      for (let i = 0; i < count; ++i) {
        if (this.selectedAnswers[i] === RTRConst.ANSWER_UNSELECTED) {
          ++unanswered;
        } else {
          answers.push({
            id: this.data.questions[i].id,
            value: this.selectedAnswers[i]
          });
        }
      }
      // allow submit if everything answered and not already submitting
      if (unanswered === 0 && !this.state.hasSubmitted) {
        this.props.sendAnswers(this.data.id, this.props.team, answers, this.convertSelectedState());
        this.setState({hasSubmitted: true});
        // unselect quest
        // continue to next screen
        RTRAct.viewFadeOut("rtrQuest");
        setTimeout(() => this.setState({goToNextScreen: true}), RTRConst.FADE_TIME);
      } else {
        // TO DO: notify user of unanswered fields
        console.log("not submitting, unanswered questions or already submitted");
      }
    }
  }

  convertSelectedState() {
    if (this.state.selectedState !== undefined) {
      return RTRConst.US_STATES_SHORT[this.state.selectedState - 1];
    }
    return undefined;
  }

  render() {

    //video
    //change url, share link or id into embedded format
    function formatVideo(video){      
      const formattedVideo = 
        video.split("https://youtu.be/")[1] ? video.split("https://youtu.be/")[1] : 
        video.split("https://www.youtube.com/watch?v=")[1] && video.split("https://www.youtube.com/watch?v=")[1];
      return `https://www.youtube.com/embed/${formattedVideo ? formattedVideo : video}?modestbranding=1&rel=0`;
    }

    //set video path
    let videoPath = this.props.sessionData.data.feature.video ? formatVideo(this.props.sessionData.data.feature.video) : undefined;

    // get quest data
    let questDisplay = <div className="rtrQuestBlock">Loading...</div>;
    if (this.props.questLoaded) {
      let color = this.props.questData.data.category.color;
      this.data = RTRAct.getQuestData(this.props.questData);
      let questBlocks = [];
      let catID = this.data.categories[0].id;
      let catColor = (catID !== undefined) ? catID.charAt(0).toUpperCase() + catID.slice(1) : "Gray";
      let categoryText = "rtrCatCol" + catColor;
      let categoryButton = "rtrCatButton" + catColor;
      let category = <RTRCatIcon category={this.data.categories[0].id} big={true}/>;
      let headerVideo = (this.props.questFeatured) ? <iframe title="Featured Questie video" className="rtrBigVideo" src={videoPath} allowFullScreen frameBorder="0"></iframe> : "";
      let description = (this.data.description) ? <div className="rtrQuestDesc">{this.data.description}</div> : "";

      if(headerVideo) {
          category = "";
      }

      // determine initial block
      questBlocks.push(
        <div className="rtrQuestBlock" key="initialBlock">
          {category}
          <h1 className={"rtrDesktopHeader " + categoryText} style={{color: color}}>{this.data.title}</h1>
          {headerVideo}
          {description}
        </div>
      );
      // add state form if featured
      if (this.props.questFeatured) {
        questBlocks.push(
          <div className="rtrQuestBlock" key="stateBlock">
            <h2 className={categoryText} style={{color: color}}>State</h2>
            <RTRQuestStateForm selectedState={this.state.selectedState} valueChange={this.doSelectState} disabled={(this.props.questPreview) ? "disabled" : ""}/>
          </div>
        );
      }
      // make question blocks
      for (let i = 0; i < this.data.questions.length; ++i) {
        let answerButtons = [];
        for (let j = 0; j < this.data.questions[i].answers.length; ++j) {
          answerButtons.push(
            <button className={"rtrButton " + categoryButton} id={"answer-" + i + "-" + j} key={"answer-" + i + "-" + j} onClick={() => this.doSelectAnswer(i, j)} disabled={(this.props.questPreview) ? "disabled" : ""}>{this.data.questions[i].answers[j]}</button>
          );
        }
        questBlocks.push(
          <div className="rtrQuestBlock rtrQuestionBlock" id={"questionBlock" + i} key={"questionBlock" + i}>
            <h2 className={categoryText} style={{color: color}}>{this.data.questions[i].question}</h2>
            <div className="rtrQuestAnswers">
              {answerButtons}
            </div>
          </div>
        );
      }
      // make submission block
      let explanation = (this.props.questPreview ? <h2 className={categoryText}>You already added data for today. Come back tomorrow to add more data and score more points for your team!</h2> : "");
      questBlocks.push(
        <div className="rtrQuestBlock" key="lastBlock">
          {explanation}
          <button className="rtrButton" disabled={this.props.questPreview} onClick={this.doSendData}>SEND DATA</button>
        </div>
      );
      questDisplay = <div>{questBlocks}</div>;
    }
    return (
      <div className="rtrView rtrViewHide rtrQuest" id="rtrQuest">
        <div className="rtrContent">
          <div className="rtrMobileHeader">
            <h3>{(this.data !== undefined) ? this.data.title : "Loading..."}</h3>
          </div>
          {questDisplay}
          <DelayLink className="rtrButton rtrButtonX" to="/Home" timer={RTRConst.FADE_TIME} onClick={() => RTRAct.viewFadeOut("rtrQuest")}></DelayLink>
        </div>
        {(this.state.goToNextScreen) ? <Redirect push to="/QuestResult" /> : ""}
      </div>
    );
  }
}

class RTRQuestStateForm extends React.Component {
  render() {
    const {selectedState, valueChange, disabled} = this.props;
    if (selectedState === undefined) {
      console.log("ERROR: selectedState property not defined");
    }
    if (valueChange === undefined) {
      console.log("ERROR: valueChange property not defined");
    }
    let options = [];
    for (let i = 0; i < RTRConst.US_STATES.length; ++i) {
      options.push(<option value={i + 1} key={"state" + i}>{RTRConst.US_STATES[i]}</option>);
    }
    return (
      <select className="rtrQuestStateForm" id="rtrQuestStateForm" name="state_id" value={selectedState} onChange={valueChange} disabled={disabled}>
        {options}
      </select>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionData: state.sessionData,
    questData: state.questLoadedData,
    questLoaded: !state.questLoading,
    questPreview: state.questViewedIsPreview,
    questFeatured: state.questViewedIsFeatured,
    team: state.teamID
  };
}

const mapDispatchToProps = {
  sendAnswers: (id, team, answers, state) => (RTRAct.sendAnswers(id, team, answers, state))
}

export default connect(mapStateToProps, mapDispatchToProps)(RTRQuest);
