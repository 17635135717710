import React from "react";

class VertCenterText extends React.Component {
  render() {
    return (
      <span style={{
        display: "flex",
        height: "100%",
        flexDirection: (this.props.alignRight === true ? "row-reverse" : "unset")
        }}>
        <span style={{
          paddingLeft: (this.props.bumpLeft === true ? "10pt" : "unset"),
          margin: (this.props.hCenter === true ? "auto" : "auto 0")
          }}>
          {this.props.children}
        </span>
      </span>
    );
  }
}

export default VertCenterText;
