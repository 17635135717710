import React from "react";
import "./CatIcon.scss";
import iconBlue from "./img/icon-cat/blue.png";
import iconGreen from "./img/icon-cat/green.png";
import iconOrange from "./img/icon-cat/orange.png";
import iconPink from "./img/icon-cat/pink.png";
import iconPurple from "./img/icon-cat/purple.png";
import iconRed from "./img/icon-cat/red.png";
import iconTeal from "./img/icon-cat/teal.png";
import iconYellow from "./img/icon-cat/yellow.png";
import logo from "./img/logo.svg";

class RTRCatIcon extends React.Component {
  render() {
    const { category, title, big } = this.props;
    // get icon to render
    let iconSrc;
    switch(category) {
      case "Blue":
      case "blue":
      case "nature-girl":
        iconSrc = iconBlue;
        break;
      case "Green":
      case "green":
      case "green-machine":
        iconSrc = iconGreen;
        break;
      case "Orange":
      case "orange":
      case "builder":
        iconSrc = iconOrange;
        break;
      case "Pink":
      case "pink":
      case "tech-whiz":
        iconSrc = iconPink;
        break;
      case "Purple":
      case "purple":
      case "helper":
        iconSrc = iconPurple;
        break;
      case "Red":
      case "red":
      case "health-guru":
        iconSrc = iconRed;
        break;
      case "Teal":
      case "teal":
      case "investigator":
        iconSrc = iconTeal;
        break;
      case "Yellow":
      case "yellow":
      case "designer":
        iconSrc = iconYellow;
        break;
      // fallback for not found
      default:
        iconSrc = logo;
        break;
    }
    // get icon size
    let iconSize;
    if (big) {
      iconSize = "rtrCatIconBig";
    } else {
      iconSize = "rtrCatIconSmall";
    }
    return (
      <img src={iconSrc} alt={(title !== undefined) ? title : category + " icon"} className={iconSize}/>
    );
  }
}

export default RTRCatIcon;
